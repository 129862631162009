<template>
  <div class="sgl-news">
    <section class="content-section">
      <news-latest/>
    </section>
    <section class="content-section">
      <news-all/>
    </section>
    <scroll-top/>
  </div>
</template>

<script>
import ScrollTop from "@/components/UIElements/scrollTop";
import NewsLatest from "@/components/News/news-latest";
import NewsAll from "@/components/News/news-all";

export default {
  name: "sglNews",
  components: {NewsAll, NewsLatest, ScrollTop},
}
</script>

<style scoped>

</style>