<template>
  <div class="inner-container">
    <div class="section-title section-title__hero-text">Новости</div>
    <template v-if="dataLoaded">
      <div class="news-grid" v-if="this.latestNews.length">
        <news-card v-for="singleNews in this.latestNews" :key="singleNews.id" :single-news="singleNews"/>
      </div>
      <div class="empty" v-else>К сожалению, новостей нет. Приходите позже :(</div>
    </template>
    <template v-else>
      <div class="news-grid">
        <skelet-rectangle :height="300" :width="999999" v-for="(s,index) in 5" :key="index"/>
      </div>
    </template>
  </div>
</template>

<script>
import NewsCard from "@/components/News/news-card";
import {mapActions} from "vuex";
import SkeletRectangle from "@/views/sceleton/components/rectangle";

export default {
  name: "news-latest",
  components: {SkeletRectangle, NewsCard},
  data() {
    return {
      payload: {
        page_number: 1,
        page_size: 3
      },
      latestNews: [],
      dataLoaded: false
    }
  },
  computed: {},
  methods: {
    ...mapActions([
      'FETCH_LATEST_NEWS',
    ]),
    getPageSize() {
      if (window.matchMedia("(min-width: 769px)").matches) this.payload.page_size = 5
    },
    setLatestNewsToData(data) {
      return this.latestNews = data
    },
  },
  async mounted() {
    this.dataLoaded = false
    this.getPageSize()
    this.setLatestNewsToData(await this.FETCH_LATEST_NEWS(this.payload))
    this.dataLoaded = true
  }
}
</script>

<style scoped>

</style>