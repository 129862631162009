<template>
  <div class="inner-container">
    <div class="section-head">
      <h2 class="section-title" @click="this.getMoreNews">Все статьи</h2>
    </div>
    <div class="sorting-panel sorting-panel__with-icons sorting-panel__with-counter sorting-panel__news">
      <ul class="icon-tabs-list">
        <!--у элементов есть класс active, для подчеркивания-->
        <li class="all icon-tabs-list-item" @click="getAllNews" :class="{active: activeFilter === 0}">Все</li>
        <li class="icon-tabs-list-item"
            v-for="(game,index) in GET_SPONSOR_GAMES"
            :key="index"
            @click="filterByGame(game, index)"
            :class="{active: activeFilter === index + 1}">
          <img :src="game.logo" :alt="game.attributes.name">
        </li>
      </ul>
      <div class="sgl-input" :class="{error: this.errorActive}">
        <div class="error-text">Минимальная длинна - 3 символа</div>
        <svg class="sgl-input__magnifier" width="16" height="16" viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
              stroke="#B0B0B0" stroke-linejoin="round"/>
          <path d="M13.9996 13.9996L11.0996 11.0996" stroke="#B0B0B0" stroke-linejoin="round"/>
        </svg>
        <label>
          <input v-model="debounceConfig.searchQuery" type="text" placeholder="Поиск" @input="debouncedSearch">
        </label>
        <transition name="default">
          <svg v-if="debounceConfig.searchQuery.length" @click="debounceConfig.searchQuery = '';getAllNews()"
               class="sgl-input__cross" width="16" height="16"
               viewBox="0 0 16 16"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 0.5L8 8M8 8L15.5 0.5M8 8L0.5 15.5M8 8L15.5 15.5" stroke="white" stroke-linejoin="round"/>
          </svg>
        </transition>
      </div>
      <div class="extra-wrapper">
        <div class="sorting-controls__wrapper">
          <div class="sorting-controls">
            <!--"ascending" класс svg для переключения состояния-->
            <svg :class="[{ascending: this.sortOrder.type === 'ascending'}, 'sort-icon']"
                 @click="this.changeSortOrder"
                 width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M5 8H19" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8 12.5H16" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M10 17H14" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            <div class="sgl-multiselect">
              сортировать по:
              <multiselect
                  v-model="sorting.value"
                  :options="sorting.options"
                  track-by="name"
                  label="name"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :preselect-first="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  @close="sortingBy"
              >
                <template slot="singleLabel" slot-scope="{option}">
                  {{ option.name }}
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6L8 10L12 6" stroke="#B0B0B0" stroke-linejoin="round"/>
                  </svg>
                </template>
              </multiselect>
            </div>
          </div>
          <div class="teams-count">
            кол-во: <span>{{ getNewsCount }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-container">
      <div class="single-tab single-tab__news-grid" v-if="!loadingInProgress">
        <news-card v-for="singleNews in this.GET_NEWS" :key="singleNews.id" :single-news="singleNews" with-date/>
      </div>
      <div class="single-tab single-tab__news-grid" v-if="loadingInProgress">
        <skelet-rectangle v-for="(s,index) in 6" :key="index" :width="9999" :height="240"/>
      </div>
    </div>
    <div class="spinner-container newsSpinner"
         v-show="this.GET_NEWS_META['current-page'] !== this.GET_NEWS_META['last-page']"
         @click="FETCH_MORE_NEWS">
      <img :src="require('@/img/ico_spinner.png')" alt="#">
    </div>
    <div class="empty" v-if="!GET_NEWS.length">К сожалению, новостей нет. Приходите позже :(</div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapGetters, mapActions} from "vuex";
import NewsCard from "@/components/News/news-card";
import SkeletRectangle from "@/views/sceleton/components/rectangle";
import _ from 'lodash';

export default {
  name: "news-all",
  components: {SkeletRectangle, NewsCard, Multiselect},
  data() {
    return {
      sortOrder: {type: 'descending', value: '-'},
      selectedSortOption: '',
      sorting: {
        value: {value: 'createdAt', name: 'дате'},
        options: [
          {value: 'createdAt', name: 'дате'},
          {value: 'views', name: 'популярности'},
        ]
      },
      debounceConfig: {
        searchQuery: '',
        delay: 400
      },
      page_number: 1,
      page_size: 9,
      activeFilter: 0,
      filterType: '',
      isSorting: true,
      sortingType: '&sort=-createdAt',
      loadingInProgress: true,
      observer: undefined,
      allNewsLoaded: false,
      errorActive: false
    };
  },
  computed: {
    ...mapGetters([
      'GET_SPONSOR_GAMES',
      'GET_NEWS',
      'GET_NEWS_META'
    ]),
    debouncedSearch() {
      return _.debounce(this.searchByTitle, this.debounceConfig.delay)
    },
    dataToGetNews() {
      return {
        page_number: this.page_number,
        page_size: this.page_size,
        filter: this.filterType,
        sort: this.sortingType,
        isSort: this.isSorting,
        searchText: this.debounceConfig.searchQuery
      }
    },
    getNewsCount() {
      if (!this.GET_NEWS_META) return '??'
      return this.GET_NEWS_META.total
    }
  },
  methods: {
    ...mapActions([
      'FETCH_ALL_NEWS',
      'FETCH_MORE_NEWS',
    ]),
    async sortingBy() {
      this.loadingInProgress = true
      this.isSorting = true
      this.sortingType = `&sort=${this.sortOrder.value}${this.sorting.value.value}`
      this.page_number = 1

      await this.FETCH_ALL_NEWS(this.dataToGetNews).then(() => this.loadingInProgress = false)
      this.page_number++
    },
    async filterByGame(game, index) {
      this.loadingInProgress = true
      this.activeFilter = index + 1
      this.isNewsListShowed = false

      this.filterType = `&filter[game_id]=${game.id}`
      this.page_number = 1
      await this.FETCH_ALL_NEWS(this.dataToGetNews).then(() => this.loadingInProgress = false)
      this.page_number++
    },
    dropDataToLoadNews() {
      this.activeFilter = 0
      this.page_number = 1
      this.filterType = ''
    },
    async searchByTitle() {
      if (this.dataToGetNews.searchText.length < 3 && this.dataToGetNews.searchText.length > 0) {
        this.errorActive = true
        setTimeout(() => {
          this.errorActive = false
        }, 2000)
      } else {

        this.loadingInProgress = true
        this.dropDataToLoadNews()

        await this.FETCH_ALL_NEWS(this.dataToGetNews).then(() => this.loadingInProgress = false)
        this.page_number++
      }
    },
    async getAllNews() {
      this.loadingInProgress = true
      this.dropDataToLoadNews()

      await this.FETCH_ALL_NEWS(this.dataToGetNews).then(() => this.loadingInProgress = false)
      this.page_number++

    },
    changeSortOrder() {
      if (this.sortOrder.type === 'ascending') {
        this.sortOrder = {type: 'descending', value: '-'}
      } else {
        this.sortOrder = {type: 'ascending', value: ''}
      }

      this.sortingBy()
    },
    async getMoreNews() {

    },
  }
  ,
  mounted() {
    this.getAllNews()

    const target = this.$el.querySelector('.newsSpinner')

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    }

    const callback = async (entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          await this.FETCH_MORE_NEWS()
        }
      }
    }

    this.observer = new IntersectionObserver(callback, options);
    this.observer.observe(target)
  },
  beforeDestroy() {
    this.observer.disconnect()
  }
}
</script>

<style scoped>

</style>